.topicGather {
    padding: 10px;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Corrected from 'left' to 'flex-start' */
    margin-bottom: 20px;
  }
  
  .inputField {
    width: 80%;
    max-width: 300px;
    padding: 18px;
    font-size: 1.1rem;
    margin-bottom: 20px;
    border: 2px solid #ccc; 
    border-radius: 5px;
  }
  .inputField:focus {
    border-color:#4A90E2; 
    outline: none; 
  }
  
  .searchButton,
  .summaryButton {
    padding: 8px 12px;
    margin-bottom: 10px;
    width: auto; 
  }
  
  @media (max-width: 768px) {
    .inputField {
      width: 100%;
      font-size: 1.1rem!important;
      padding: 8px !important;
    }
    .searchButton,
    .summaryButton {
      width: auto;
    }
    .inputContainer {
      margin-right: 15px;
      padding: 5px;
    }
  }