/* Login form container centered */
.form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto; /* Center form horizontally */
    padding: 20px;
    border: 1px solid #444; /* Darker border to match the dark theme */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow */
    background-color: #333333;
    position: absolute; /* Center form vertically */
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Styling for the input fields */
.input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #555; /* Darker border for inputs */
    border-radius: 4px;
    font-size: 18px;
    background-color: #2a2a2a; /* Dark background for inputs */
    color: #e6e6e6; /* Light text color (cream-like) */
    width: 100%; /* Ensures consistent width across fields */
    box-sizing: border-box; /* Ensures padding is included within the width */
}

/* Password container to position the icon */
.passwordContainer {
    position: relative;
    width: 100%;
}

/* Adjusting input inside the password container */
.passwordContainer .input {
    padding-right: 40px; /* Space for the eye icon */
}

/* Show password icon positioning */
.showPasswordIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888;
    font-size: 18px;
}

.showPasswordIcon:hover {
    color: #333;
}

/* Styling for the login button */
.button {
    padding: 10px;
    background-color: #444; /* Darker gray button */
    color: #e6e6e6; /* Light text color for the button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

/* Hover effect for the login button */
.button:hover {
    background-color: #666; /* Slightly lighter hover effect */
}

/* Styling for the error message */
.error {
    color: #e63946; /* Muted red for error text */
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

/* Styling for loading spinner */
.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Changed color to a nicer blue */
    border-radius: 50%;
    width: 30px; /* Increased size */
    height: 30px; /* Increased size */
    animation: spin 1s ease-in-out infinite; /* Adjusted animation timing */
    margin: 10px auto; /* Center spinner horizontally */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
