.chatContainer {
  width: 80%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.chatHistory {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background: #f5f5f5;
  max-height: 600px;
}

.message {
  margin-bottom: 10px;
}

.messageContent {
  white-space: pre-wrap;
  overflow-x: auto;
}

.formContainer {
  padding: 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.textarea {
  width: 100%;
  margin-bottom: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.submitButton {
  align-self: flex-end;
}

.chatButton {
  margin-bottom: 10px;
}

/* Image style for full screen */

.image {
  max-width: 100%;
  object-fit: contain;
}

/* Add horizontal scroll for images on mobile */
.scrollableImageContainer {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.errorMessage {

  padding: 10px;
  margin: 5px 0;
 
  border: 1px solid #ff9999;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 80px; /* Adjust for max visibility */
  word-wrap: break-word;
}


/* Enable scrollable image with both horizontal and vertical scroll on smaller screens */
@media (max-width: 768px) {
  .chatContainer {
      width: 99%;
      padding: 10px;
      padding-top: 20px;
  }

  .chatContainer, .chatHistory, .message, .messageContent, .formContainer, .textarea, .submitButton {
      font-size: 0.92rem;
  }

  .image {
    max-width: none;
    height: 400px;
  }

}
