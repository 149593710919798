.chatContainer {
    width: 80%;
    margin: 0;
  }
  
  
  .chatBox {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 800px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    padding: 5px;
  }
  
  .chatHistory {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background: #f5f5f5;
    max-height: 800px;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .messageContent {
    white-space: pre-wrap;
  }
  
  .formContainer {
    padding: 10px;
    background: #fff;
    border-radius: 8px;
  }
  
  .textarea {
    width: 100%;
    margin-bottom: 10px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .submitButton {
    align-self: flex-end;
  }

  @media (max-width: 768px) {
    .chatContainer {
      width: 100%;
    }
    .chatBox, .chatHistory, .message, .messageContent, .formContainer, .textarea, .submitButton {
      font-size: 1rem; /* Increase font size for mobile */
  }
  }