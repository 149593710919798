/* Container for the whole component */
.container {
  margin-left: 30px;
  margin-top: 50px;
  max-width: 800px; /* Limit the width to align content */
}

/* Heading styling */
.heading {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

/* Button styling */
.button {
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #e0e0e0;
  color: #333;
  border: 1px solid #ccc;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: #d6d6d6;
}

.button.active {
  background-color: #1976d2;
  color: white;
  border: 1px solid #1565c0;
}


/* Active section container styling */
.activeSection {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease;
  opacity: 0;
  max-width: 700px; /* Ensure consistent width with buttons */
  margin-left: 30px;
}


.activeSectionSmartSearch {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease;
  opacity: 0;
  max-width: 80%; 
  margin-left: 30px;
}

.activeSectionSmartSearch.active {
  opacity: 1;
}

.activeSection.active {
  opacity: 1;
}

/* Back link container styling */
.backLinkContainer {
  margin-top: 40px;
  margin-left: 30px;
}

/* Back link styling */
.backLinkContainer a {
  color: #1976d2;
  text-decoration: none;
  font-size: 1em;
}

.backLinkContainer a:hover {
  text-decoration: underline;
}

/* Button container to add margin between buttons and heading */
.buttonContainer {
  margin-top: 20px;
}

/* Styling for the input box container */
.companyInputBox, .economyInputBox, .foreignExchangeInputBox, .cryptoInputBox, .bondInputBox, .commodityInputBox {
  padding: 20px;
  border: none; 
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem; /* Standardize font size */
}

/* Styling the input fields */
.companyInputBox input, .economyInputBox input, .foreignExchangeInputBox input, .cryptoInputBox input, .bondInputBox input, .commodityInputBox input {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-size: 1rem;
  width: 200px;
}

.companyInputBox h5, .economyInputBox h5, .foreignExchangeInputBox h5, .cryptoInputBox h5,  .bondInputBox h5, .commodityInputBox h5,.commodityInputBox button {

    font-size: 1rem;
  }

/* Styling the submit button inside the input box */
.companyInputBox button, .economyInputBox button, .foreignExchangeInputBox button, .cryptoInputBox button, .bondInputBox button, .commodityInputBox button {
  padding: 10px 15px;
  border-radius: 3px;
  background-color: #1976d2;
  color: white;
  border: none;
  cursor: pointer;
}

.companyInputBox button:hover, .economyInputBox button:hover, .foreignExchangeInputBox button:hover, .cryptoInputBox button:hover, .bondInputBox button:hover, .commodityInputBox button:hover {
  background-color: #1565c0;
}

.commodityInputBox {
  padding-bottom: 200px; /* Add space below to avoid clipping */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {

  .container {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px;
    max-width: 100%;
  }


  .button {
    width: 60%;
    margin: 0 auto 10px auto;
    font-size: 1rem; 
    padding: 12px;
    display: block;
    font-size: 1.2rem; 
  }

  /* Ensure enough space below for the dropdown menu */
  .activeSection {
    padding: 15px;
    margin-top: 40px;
    margin-left: 0;
    margin-bottom: 100px; /* Add extra margin at the bottom for dropdown */
    max-width: 100%; /* Full width for the active section */;
  }

    /* Ensure enough space below for the dropdown menu */
    .activeSectionSmartSearch {
      padding: 1px;
      margin-top: 40px;
      margin-left: 0;
      margin-bottom: 100px; /* Add extra margin at the bottom for dropdown */
      max-width: 100%; /* Full width for the active section */;
    }
    
  
  .companyInputBox, .economyInputBox, .foreignExchangeInputBox, .cryptoInputBox, .bondInputBox {
    padding: 10px;
  }

  .companyInputBox input, .economyInputBox input, .foreignExchangeInputBox input, .cryptoInputBox input, .bondInputBox input{
    width: 100%;
    margin-bottom: 10px;
    font: 1rem;
    width: 190px;
  }

  .companyInputBox button, .economyInputBox button, .foreignExchangeInputBox button, .cryptoInputBox button, .bondInputBox button {
    width: 90%;
    margin-bottom: 10px;
  }
  
  /* Ensure horizontal scrolling for commodity buttons */
  .commodityInputBox {
    display: flex;
    flex-direction: row; /* Keep the buttons in a row */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent buttons from wrapping to the next line */
    padding-bottom: 200px; /* Add space below to avoid clipping */
  }

  .commodityInputBox button {
    display: inline-block; /* Ensure buttons remain inline */
    min-width: 120px; /* Ensure buttons have a minimum width */
    margin-right: 10px; /* Add space between buttons */
    font-size: 1rem; /* Keep font size consistent */
  }



  /* Ensure back link is centered on small screens */
  .backLinkContainer {
    margin-left: 0;
    text-align: left;
    margin-bottom: 30px;
  }

  .heading {
    text-align: center;
    margin-bottom: 50px;
  }


}





