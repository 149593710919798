/* SignalsBacktestTechnicalsPlot.module.css */

.chartContainer {
    width: 50%;
    margin-left: 10px; /* Center the chart container on desktop */
}

.chartWrapper {
    min-width: 600px; /* Prevent the chart from becoming too narrow on desktop */
    height: 400px; /* Set a fixed height for the chart on desktop */
}

@media only screen and (max-width: 768px) {
    .chartContainer {
        width: 100%;
        overflow-x: auto; /* Enable horizontal scrolling if needed */
    }
    .chartWrapper {
        min-width: 300px; /* Reduce min-width on mobile */
        height: 250px; /* Reduce chart height on mobile */
    }
}
