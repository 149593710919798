/* General Layout */
body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
}

/* Wrapper styles to ensure min-height is 100vh */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  height: auto;
}

/* Header Styles */
.navbar {
  background-color: #333;
  color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar .navbar-left, .navbar .navbar-right {
  display: flex;
  align-items: center;
}

.navbar .navbar-left {
  padding-left: 20px;
}

.navbar .navbar-right {
  padding-right: 50px;
  gap: 15px;
}

.navbar a, .navbar button {
  color: #f2f2f2;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.05em; 
  transition: background 0.3s, color 0.3s;
}

.navbar button {
  color: #f2f2f2;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.05em; 
  transition: background 0.3s, color 0.3s;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit; 
}

.navbar a:hover, .navbar button:hover{
  background-color: #555;
  border-radius: 5px;
}

a {
  text-decoration: none;
}


/* Hamburger Menu Button: Hidden on larger screens */
.mobile-menu-icon {
  display: none;
  background: none;
  border: none;
  color: #f2f2f2;
  font-size: 1.5em;
}

/* Mobile Menu Links: hidden by default */
.nav-links {
  display: flex;
  gap: 15px;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap'); 

.logo-text {
  font-family: 'Nunito', sans-serif; 
  font-size: 1.55em !important; 
  color: #e5e3dd; 
  text-decoration: none; 
  font-weight: 800;
  display: flex;
  align-items: center;
}

.custom-f {
  position: relative;
  display: inline-block;
}


/* Hero Section */
.hero {
  padding: 20px; 
  text-align: center;
  background-color: transparent; 
  color: #333; 
  margin-top: 40px;
}

.hero h1 {
  margin: 0;
  font-size: 2.6em; 
  font-weight: bold;
  margin-bottom: 15px; 
  color: #222; 
}

.hero p {
  font-size: 1.4em;
  margin-top: 20px; 
  color: #666; 
}

/* Sidebar Styles */
.sidebar {
  width: 220px;
  background-color: #2b2b2b;
  padding: 10px;
  /*height: calc(100vh - 60px); */
  min-height: 100vh;
  position: fixed;
  top: 60px;
  left: 0;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sidebar.collapsed {
  width: 50px;
}

.sidebar nav a {
  display: flex;
  align-items: center;
  color: #f2f2f2;
  padding: 12px;
  margin-bottom: 12px;
  transition: background 0.3s, color 0.3s, transform 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  border-radius: 5px;
}

.sidebar nav a:hover {
  background-color: #444;
  transform: translateX(5px);
}

.sidebar nav a .icon {
  margin-right: 12px;
  font-size: 1.4em;
}

.sidebar.collapsed nav a .text {
  display: none;
}

.sidebar.collapsed nav a:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  left: 60px;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Main Content */
.main-content {
  position: relative;
  z-index: 1;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  min-height: auto;
  height: auto;
  overflow: visible;
}

.content-area {
  margin-left: 220px;
  padding: 20px;
  flex-grow: 1;
  display: block;
  flex-direction: column;
  align-items: center;
  height: auto;
  max-width: calc(100vw - 220px);
  padding-bottom: 120px; 
  box-sizing: border-box;
}

.sidebar.collapsed + .content-area {
  margin-left: 60px;
}

/* Grid Layout */
.app-page .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 50px 0; 
  max-width: 1200px; 
  margin: 0 auto; 
  flex-grow: 1;
  justify-items: center; 
}


.app-page .grid-item {
  background-color: #2b2b2b;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  color: white;
  transition: transform 0.3s, background 0.3s, box-shadow 0.3s;
  width: 250px;
  height: 154.5px; 

}

.app-page .grid-item:hover {
  background-color: #3a3a3a;
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Grid Content */
.app-page .grid-content h3 {
  margin: 0;
  font-size: 1.6em; 
  font-weight: 600;
  margin-top: 5px; 
  margin-bottom: 15px; 
}

.app-page .grid-content p {
  font-size: 1.1em;
  color: #ddd;
  margin-bottom: 10px; 
}

.app-page .grid-content .grid-icon {
  font-size: 2.2em; 
  color: #f2f2f2; 
  margin-bottom: 10px; 
}
.app-page .grid-item:hover .grid-icon {
  color: #ddd; 
}


/* Footer Styles */
footer {
  background-color: #333;
  color: #f2f2f2;
  text-align: center;
  padding: 5px;
  width: 100%;
  position: relative;
  bottom: 0;
  font-size: 1em;
}

footer a {
  color: #f2f2f2;
  text-decoration: none;
  font-weight: 500;
}

footer h1 {
  font-size: 1.05em; 
}

footer p {
  font-size: 0.8em; 
}


button {
  padding: 8px 12px; /* Adjust padding */
  font-size: 16px;   /* Adjust font size */
  border-radius: 8px; /* Optional for rounded corners */
  border: 1px solid #ccc; /* Light gray border for a minimalist look */
  background-color: #f5f5f5; /* Light gray background */
  color: #333;      /* Dark gray text color */
  cursor: pointer;   /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for hover effect */
}

button:hover {
  background-color: #e0e0e0; /* Slightly darker shade on hover */
  border-color: #bbb; /* Slightly darker border on hover */
}



/* .user-initials styles */
/* .user-initials styles */
.user-initials-dropdown {
  position: relative;
  display: inline-block;
}

.user-initials-button {
  background-color: #555; /* Dark background for initials */
  color: #fff; /* White color for contrast */
  font-weight: bold; /* Makes the initials stand out */
  width: 40px; /* Ensures a round shape */
  height: 40px; /* Ensures a round shape */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Makes it a circle */
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  font-size: 1.2rem; /* Slightly larger font for better readability */
}

.user-initials-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Hover effect */
}

.user-initials-dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  border: 1px solid #555;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  z-index: 1;
  border-radius: 0.25rem;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  color: #f2f2f2;
  opacity: 0;
  animation: fadeIn 0.3s forwards; /* Fade-in effect */
}

.user-initials-dropdown-menu button {
  width: 100%;
  padding: 10px 16px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease;
  border-radius: 0.25rem;
  color: #f2f2f2;
}

.user-initials-dropdown-menu button:hover {
  background-color: #555;
}

/* Fade-in animation for dropdown */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}



/* Responsive Grid Layout */
@media (max-width: 1024px) {
  .content-area {
    margin-left: 60px; 
    padding: 20px;
  }

  .app-page .grid-container {
    grid-template-columns: 1fr 1fr; 
    gap: 80px;
  }

  .app-page .grid-item {
    width: 90%; 
    max-width: 100%;
  }
}


@media (max-width: 768px) {

  .content-area {
    padding: 15px;
    margin-bottom: 20px;
    margin-top: 10px;
    max-width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .app-page .grid-container {
    grid-template-columns: 1fr; 
    gap: 35px;
  }

  .app-page .grid-item {
    padding: 25px;
    width: 60%; 
    max-width: 90%;
    
  }

  .sidebar {
    display: none;
  }


  /* Show the mobile menu icon */
  .mobile-menu-icon {
    display: block;
    background: none;
    border: none;
    color: #f2f2f2;
    font-size: 1.5em;
    cursor: pointer;
    position: relative; /* Ensure it can be used as a reference for absolute positioning */
  }

  .nav-links {
    display: none; /* Hide links by default */
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
    text-align: center;
  }

  /* Show the nav-links when the hamburger is clicked */
  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; 
    right: 50px;
    width: auto; 
    max-width: 300px; 
    background-color: #444;
    padding: 0.3rem;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  /* Adjust padding for right section on mobile */
  .navbar-right {
    padding-right: 20px;
  }

  /* Add smooth transition for visibility */
  .nav-links {
    transition: all 0.3s ease-in-out;
  }

  .hero {
    padding: 0px; 
    text-align: center;
    background-color: transparent; 
    color: #333; 
    margin-top: 40px;
    font-size: 0.8em;
  }
  

}

