/* LongTermOutlook.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 10px;
    width: 100%;
  }
  
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    max-width: 80%;
  }
  
  
  .button {
    flex-basis: calc(50% - 10px);
    background-color: #f8f9fa;
    color: black;
    border: 1px solid #ddd;
    cursor: pointer;
    max-width: 100%;
  }
  
  .buttonActive {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }

  .chartControls {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .chartControlLabel {
    font-size: medium;
  }
  
  .chartControlInput {
    width: 50px;
    margin-right: 10px;
  }
  
  .updateButton {
    margin-left: 0px;
  }
  
  .chartHeader {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .chartWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 80%;
  }
  
  .chartContainer {
    flex: 1 0 calc(50% - 20px); /* Two charts per row on larger screens */
    max-width: calc(50% - 20px);
    margin-bottom: 20px;
  
  }
  
  .scrollableChart {
    overflow-x: auto;
  }
  
  .chartContent {
    width: 100%; 
    min-width: 0;
  }
  
  @media (max-width: 768px) {
    .chartContainer {
        flex: 1 0 calc(100% - 20px); /* Full width on smaller screens */
        max-width: 100%;
      }

    .chartContent {
        min-width: 500px;
      }
    .scrollableChart {
        overflow-x: auto;
      }

    .buttonContainer {
        max-width: 100%;
      }
      .chartWrapper {
        width: 100%;
      }

      .button {
        flex-basis: 100%; /* Full width on mobile */
        margin-left: 20px;
        margin-right: 20px;
    }
  }
  