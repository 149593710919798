.customDropdown {
    position: relative;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
}
  
.selected {
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    word-wrap: break-word; /* Allow breaking words */
}
  
.arrow {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
}
  
.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    max-height: 350px;
    overflow-y: auto;
    z-index: 100;
}
  
.dropdownOption {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}
  
.dropdownOption:hover {
    background-color: #007bff;
    color: white;
}

@media (max-width: 768px) {
    .customDropdown {
        width: 100%;
    }

    .dropdownMenu {
        max-height: 250px;
    }
      
      
  
  }
  