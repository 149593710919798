.insightExplorer {
    padding: 0px;
  }
  
  .chatContainer {
    width: 90%;
    margin: 0;
  }
  
  .chatHistory {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background: #f5f5f5;
    max-height: 600px;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .messageContent {
    white-space: pre-wrap;
  }
  
  .textarea {
    width: 100%;
    margin-bottom: 10px;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .submitButton {
    align-self: flex-end;
  }
  
  .chatButton {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .chatContainer {
        width: 100%;
    }
    .messageContent {
        font-size: 16px; /* Increase font size for mobile */
    }
    .textarea {
        font-size: 16px; /* Increase font size for mobile */
    }
    .message {
        font-size: 16px; /* Further increase font size for mobile */
    }
}