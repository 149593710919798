/* Container for the whole component */
.container {
  margin-left: 30px;
  margin-top: 50px;
  max-width: 800px; /* Limit the width to align content */
}

/* Heading styling */
.heading {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

/* Button styling */
.button {
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #e0e0e0;
  color: #333;
  border: 1px solid #ccc;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: #d6d6d6;
}

.button.active {
  background-color: #1976d2;
  color: white;
  border: 1px solid #1565c0;
}

/* Active section container styling */
.activeSection {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease;
  opacity: 0;
  max-width: 700px; /* Ensure consistent width with buttons */
  margin-left: 30px;
}

.activeSection.active {
  opacity: 1;
}

/* Back link container styling */
.backLinkContainer {
  margin-top: 40px;
  margin-left: 30px;
}

/* Back link styling */
.backLinkContainer a {
  color: #1976d2;
  text-decoration: none;
  font-size: 1em;
}

.backLinkContainer a:hover {
  text-decoration: underline;
}

/* Button container to add margin between buttons and heading */
.buttonContainer {
  margin-top: 20px;
}

/* Styling for the input box container */
.earningsInputBox, .secInputBox, .documentInputBox, .videoInputBox {
  padding: 20px;
  border: none; 
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem; /* Standardize font size */
}

/* Styling the input fields */
.earningsInputBox input, .secInputBox input, .documentInputBox input, .videoInputBox input {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-size: 1rem;
  width: 200px;
}

.earningsInputBox h5, .secInputBox h5, .documentInputBox h4, .videoInputBox h4, .documentInputBox button, .videoInputBox button {
  font-size: 1rem;
}

/* Styling the submit button inside the input box */
.earningsInputBox button, .secInputBox button, .documentInputBox button, .videoInputBox button {
  padding: 10px 15px;
  border-radius: 3px;
  background-color: #1976d2;
  color: white;
  border: none;
  cursor: pointer;
}

.earningsInputBox button:hover, .secInputBox button:hover, .documentInputBox button:hover, .videoInputBox button:hover {
  background-color: #1565c0;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {

  .container {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 30px;
      max-width: 100%;
      padding: 10px;
  }

  .button {
    width: 60%;
    margin: 0 auto 10px auto;
    font-size: 1rem; 
    padding: 12px;
    display: block;
    font-size: 1.2rem; 
  }

  .activeSection {
      padding: 15px;
      margin-left: 0!; 
      margin-right: 0; 
      max-width: 100%; 
   
  }
  /* Ensure enough space below for the dropdown menu */
  .activeSection {
      padding: 15px;
      margin-top: 40px;
      margin-left: 0;
      margin-bottom: 100px; /* Add extra margin at the bottom for dropdown */
      max-width: 100%; /* Full width for the active section */;
  }

  .earningsInputBox, .secInputBox, .documentInputBox, .videoInputBox {
      padding: 10px;
  }

  .earningsInputBox input, .secInputBox input, .documentInputBox input, .videoInputBox input{
      width: 90%;
      margin-bottom: 10px;
  }

  .earningsInputBox button, .secInputBox button, .documentInputBox button, .videoInputBox button {
      width: 90%;
      margin-bottom: 10px;
  }

  .backLinkContainer {
      margin-left: 0;
      text-align: left;
      margin-bottom: 30px;
  }

  .heading {
      text-align: center;
      margin-bottom: 50px;
  }
}

