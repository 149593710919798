.chart-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap; /* Prevent wrapping */
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent internal elements from wrapping */
  }
  
  .chart-wrapper {
    flex-grow: 1;
    min-width: 700px; /* Adjust min-width to ensure chart takes more space */
    height: 60vh; /* Set height */
    padding-left: 10px;
    padding-right: 10px;
    width: auto; /* Allow chart to grow beyond fixed width */
    overflow-x: auto; /* Allow chart-specific scrolling if necessary */
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .legend {
    flex-shrink: 0; /* Ensure the legend doesn't shrink */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 150px; /* Give the legend a fixed width */
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 200px;
  }
  
  .sectionQuarter {
    width: 100%; /* Ensure full width */
    height: auto; /* Adjust height automatically */
  }
  
  .sectionContent {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .chart-container {
      flex-direction: row; /* Side-by-side on mobile */
      overflow-x: scroll; /* Enable horizontal scrolling */
      width: 100vw; /* Full viewport width */
      white-space: nowrap; /* Prevent wrapping */
    }
  
    .chart-wrapper {
      min-width: 600px; /* Ensure chart takes space */
      height: 40vh; /* Reduce height on mobile */
      margin-top: 40px;
    }
  
    .legend {
      width: 100%; /* Full width for legend */
      margin-right: 0;
      margin-top: 130px;
      text-align: center;
    }
  
    .sectionQuarter {
      width: 100vw; /* Full width */
      padding: 0;
    }
  
    .sectionContent {
      width: 100%;
      height: auto;
    }
  }
  