.earningsPress {
    padding: 0px;
  }
  
 
  
  .mainContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .highlightSection {
    flex: 1;
    overflow-y: auto;
    max-height: 1000px;
  }
  
  .chatSection {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .highlightButton, 
  .chatButton, 
  .askButton {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .chatContainer {
    overflow-y: auto;
    max-height: 1000px;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
  }
  
  .inputSection {
    display: flex;
    flex-direction: column;
  }
  
  .questionInput {
    padding: 5px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    height: 150px;
    box-sizing: border-box; 
  }

  .customDropdown {
    position: relative;
    width: 34%;
  }
  


  .selected {
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between; /* Align arrow to the right */
    align-items: center;
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1000;
  }
  
  .dropdownOption {
    padding: 10px;
    cursor: pointer;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
  
  .dropdownOption:hover {
    background-color: #f1f1f1;
  }
  
  .arrow {
    margin-left: 18px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid grey;
  }
  
  @media (max-width: 768px) {
    .mainContainer {
      flex-direction: column;
    }
    
    .highlightSection, 
    .chatSection {
      width: 100%;
    }

    .chatContainer {
        padding: 2px;
      }

      .dropdownMenu {
        width: 100%;
      }

 
        
      .dropdownOption {
        max-width: 60vw; /* Ensure options do not exceed the viewport width */
        white-space: normal;
        display: block;
        padding: 10px;
      }


      .selected {
        width: 100%;
      }

      .customDropdown {
        position: relative;
        width: 60%;
      }
      
      

  }
  