/* General layout for the full screen */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  padding: 20px;
  padding-top: 80px; 
  box-sizing: border-box;
}

.main-content {
  flex-grow: 1; /* Ensures main content expands to fill available space */
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure main content doesn't cause horizontal scroll */
}

.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: auto; 
  width: 100%;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  min-height: 200px; 
}



.narrative {
  flex-basis: 33%; 
  padding: 10px;
  border-left: 1px solid #ddd; 
  text-align: left;
}

.sectionQuarter {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.sectionContent {
  flex: 1;
  position: relative;
  min-width: 700px;
}

.divider {
  height: 1px;
  background-color:#d8d8d8;
  width: 100%;
  margin-top: 20px; 
  margin-bottom: 0;
}

.dividernone {
  height: 1px;
  background-color: none;
  width: 100%;
  margin-top: 20px; 
  margin-bottom: 0;
}

/* Add this to your MicroProbe.module.css file */
.suiteinput {
  width: auto !important;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px; 
}

.suitebutton {
  width: auto !important; 
  padding: 8px !important;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {

  .container {
    padding: 0px;
    padding-top: 60px;
    width: 100%; 
    padding-bottom: 40px; 
  }

  
  .main-content {
    width: 100%; 
  }

  .footer {
    padding: 5px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
  }

  .section {
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%; 
  }

  .sectionContent, .narrative {
    width: 100%; 
  }
  
  .section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: none; 
    border-right: none; 
    padding: 0px;
    border-radius: 0px;
}
  
  .sectionContent {
    width: 100%;
    height: auto;
    flex: 1;
    position: relative;
    min-width: 100px;
  
  }

}
