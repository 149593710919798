.container {
    padding: 0px;
    width: 80%;
  }
  
  .section {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
  }

  .sectionDashboard {
    margin-top: 20px;
    border: none;
    border-radius: 8px;
    padding: 5px;
  }
  
  .sectionContent {
    padding: 1px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .divider {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}
  .urlInput {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    word-wrap: break-word;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  
  .actionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  
  .chatContainer {
    overflow-y: auto;
    max-height: 600px;
    flex-grow: 1;
    padding: 2px;
    background: #f5f5f5;
    border-radius: 8px;
    margin: 10px 0;
    width: 100%;
  }
  
  .questionInput {
    width: 100%;
    padding: 4px;
    font-size: 14px;
    height: 50px;
  }
  
  .backLink {
    margin-top: 100px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .actionsContainer {
      flex-direction: column;
    }
  
    .chatContainer {
      max-height: 1000px;
    }
  
    .questionInput {
      height: 150px;
    }
  
    .container {
      width: 100%;
    }
  }
  
  /* Ensure the parent container of questionInput takes full width */
  .questionInputContainer {
    width: 100%;
  }
  