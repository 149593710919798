.secFilingsContainer {
    padding: 0px;
    width: 80%;
  }
  
  .errorMessage {
    color: red;
    text-align: center;
  }
  
  .cikLabel {
    font-size: small;
  }
  
  .cikInfo {
    font-size: smaller;
    font-style: italic;
  }
  
  .chatContainer {
    overflow-y: auto;
    max-height: 600px;
    flex-grow: 1;
    padding: 2px;
    background: #f5f5f5;
    border-radius: 8px;
    margin: 10px 0;
  }
  
  .inputSection {
    display: flex;
    flex-direction: column;
  }
  
  input[type="text"],
  textarea {
      padding: 5px;
      margin-bottom: 10px;
      width: 100%;
      box-sizing: border-box;
  }
  
  button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    width: auto;
  }

  .customDropdown {
    position: relative;
    width: 160px; /* Adjust as needed */
  }
  
  .customDropdown__selected {
    height: 30px; /* Adjust the height as needed */
    line-height: 30px; /* Ensure the text is vertically centered */
    padding: 0 10px; /* Adjust padding as needed */
    border: 1px solid #ccc;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .customDropdown__menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 1000;
    max-height: 350px; /* Adjust as needed */
    overflow-y: auto;
  }
  
  .customDropdown__option {
    padding: 10px; /* Adjust padding as needed */
    cursor: pointer;
  }
  
  .customDropdown__option:hover {
    background: #f0f0f0;
  }
  .customDropdown__arrow {
    margin-left: 18px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid grey;
    display: inline-block;
    vertical-align: middle;
  }
  
  @media (max-width: 768px) {
    .secFilingsContainer {
      padding: 0px;
      width: 100%;
    }
  
    .chatContainer {
      padding: 5px;
    }

    input[type="text"],
    textarea {
        font-size: 1rem; 
    }
  
  }
  