/* Features Page General Styling */
.features-page {
  padding: 60px 20px 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-page h1 {
  text-align: center;
  color: #222;
  font-size: 2.4em;
  margin-bottom: 20px;
}

.features-page p {
  color: #666;
  font-size: 1.2em;
  margin-bottom: 40px;
  text-align: center;
}

/* Features List Styling */
.features-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  max-width: 1200px;
  width: 95%;
}

.feature {
  display: block; /* Make the whole box clickable */
  text-decoration: none; /* Remove link underline */
  color: inherit; /* Inherit the original text color */
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Make the box feel clickable */
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.feature h3 {
  color: #1c87c9;
  font-size: 1.4em;
  margin-bottom: 15px;
}

.feature h3:hover {
  color: #105a78;
}

.feature-subsection {
  margin-top: 15px;
  color: #444;
}

.feature-subsection strong {
  color: #007bff;
  font-weight: 600;
}

/* Ensure that all child elements within the feature box do not block the link */
.feature * {
  pointer-events: none;
}

/* Feedback Section Styling */
.feedback-section {
  margin-top: 60px;
}

.feedback-section p {
  font-size: 1.1em;
  text-align: center;
}

.feedback-section a {
  color: #1c87c9;
  text-decoration: none;
  font-weight: 500;
}

.feedback-section a:hover {
  text-decoration: underline;
}

/* Responsive Layout */
@media (max-width: 1024px) {
  .features-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .features-list {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .features-page h1 {
    font-size: 2em;
  }

  .features-page p {
    font-size: 1.1em;
  }

  .features-page {
    padding: 60px 0px 0px;
    width: 100%;
  }


}
