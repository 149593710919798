.container {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 10px;
    width: 100%;
  }
  
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    max-width: 100%;
  }
  
 
 
  .button {
    background-color: #f8f9fa;
    color: black;
    border: 1px solid #ddd;
    cursor: pointer;
    align-self: flex-start;
    margin-top: 10px;
}
  
  .buttonActive {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }
  
  .chartControls {
    display: flex;
    align-items: left;
    gap: 5px;
    margin-top: 0px;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .chartControls label {
    font-size: medium;
  }
  
  .dateInputs {
    display: flex;
    align-items: left;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .dateInputs label {
    font-size: small;
    margin-right: 5px;

  }

  .dateInputs div:nth-child(2) label {
    margin-right: 22px; 
  }
  
  .dateInput {
    width: auto;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  
  .chartWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
  }
  
  .chartContainer {
    flex: 1 0 60%;
    max-width: 60%;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .scrollableChart {
    overflow-x: auto;
  }
  
  .chartContent {
 
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {


    .buttonContainer {
      max-width: 100%;
    }
  
    .button {
      flex-basis: 100%;
    }
  
    .chartWrapper {
      width: 100%;
    }
  
    .chartContainer {
      flex: 1 0 100%;
      max-width: 100%;
    }
  
    .chartContent {
      min-width: 400px;
    }
  
    .scrollableChart {
      overflow-x: auto;
    }

    .dateInputs {
      flex-direction: column;
      gap: 5px;
    }
  
    .dateInputs label {
      font-size: medium;
    }
  
    .dateInput {
      width: 100%;
    }
  }
  