/* Container for the whole component */
.container {
  margin-left: 30px;
  margin-top: 50px;
  width: 80%;
}

/* Heading styling */
.heading {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

/* Active section container styling */
.activeSection {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1400px;
  margin-left: 0px;
}

/* Back link container styling */
.backLinkContainer {
  margin-top: 80px;
  margin-left: 30px;
}

/* Back link styling */
.backLinkContainer a {
  color: #1976d2;
  text-decoration: none;
  font-size: 1em;
}

.backLinkContainer a:hover {
  text-decoration: underline;
}

/* Button container to add margin between buttons and heading */
.buttonContainer {
  margin-top: 20px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {

  .container {
    margin-left: 0px;
    margin-top: 10px;
    width: 95%;
    padding: 5px;
}

  .activeSection {
    padding: 10px;
    margin-top: 10px;
    width: 97%;
}


  .backLinkContainer {
    margin-top: 50px;
    margin-left: 5px;
    margin-bottom: 40px;
}

  .buttonContainer {
    margin-top: 10px;
}
  .button {
      width: 100%;
      margin-bottom: 10px;
  }

  .heading {
    font-size: 1.5em;
    align-items: center;
}
}





 



