/* NearTermForecast.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    max-width: 80%;
  }
  
  .button {
    flex-basis: calc(50% - 10px);
    background-color: #f8f9fa;
    color: black;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  
  .buttonActive {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }
  
  .chartControls {
    display: flex;
    align-items: left;
    gap: 5px;
    margin-top: 0px;
    flex-direction: column;
  }
  
  .chartControls label {
    font-size: medium;
  }
  
  .chartWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
    width: 80%;
  }
  
  .chartContainer {
    flex: 1 0 calc(50% - 20px); /* Two charts per row on larger screens */
    max-width: calc(50% - 20px);
  }
  
  .scrollableChart {
    overflow-x: auto;
  }
  
  .chartContent {
    width: 100%; /* Adjust to fit the container */
    min-width: 0; /* Remove fixed minimum width */
}

  
  @media (max-width: 768px) {
    .chartContainer {
      flex: 1 0 100%; /* Full width on mobile */
      max-width: 100%;
    }

    .chartContainer {
        flex: 1 0 calc(100% - 20px); /* Full width on smaller screens */
        max-width: 100%;
      }

    .chartContent {
        min-width: 500px;
      }
    .scrollableChart {
        overflow-x: auto;
      }

    .buttonContainer {
        max-width: 100%;
      }
      .chartWrapper {
        width: 100%;
      }
      .button {
        flex-basis: 100%; /* Full width on mobile */
        margin-left: 20px;
        margin-right: 20px;
    }
  }
  