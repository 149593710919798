.autocomplete {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust based on your layout */
}

.autocomplete-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.suggestions-list {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    background-color: white;
    z-index: 1000; /* Ensure it's above other content */
}

.suggestion-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.suggestion-item:last-child {
    border-bottom: none;
}

.suggestion-item.highlighted, .suggestion-item:hover {
    background-color: #f2f2f2;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Changed color to a nicer blue */
    border-radius: 50%;
    width: 30px; /* Increased size */
    height: 30px; /* Increased size */
    animation: spin 1s ease-in-out infinite; /* Adjusted animation timing */
    margin: 10px auto; /* Increased margin */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}