.ideasMaker {
    padding: 0px;
  }
  
  .createButton {
    margin-bottom: 20px;
  }
  
  .dropdownContainer {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .customInputContainer {
    width: 100%;
    margin-top: 10px;
  }
  
  .customInput {
    width: 100%;
    height: auto;
    min-height: 50px;
  }
  
  .contentContainer {
    width: 100%;
    margin-top: 10px;
  }
  
  .contentTextarea {
    width: 100%;
    min-height: 160px;
    margin-top: 10px;
  }
  
  .resultContainer {
    overflow-y: auto;
    max-height: 600px;
    padding: 0px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-top: 20px;
    width: 100%;
  }
  
  .deleteButton {
    font-size: 0.8rem;
    padding: 5px 10px;
    margin-left: 10px;
  }

  .labelWithMargin {
    margin-right: 10px; 
}

.customDropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}

.selected {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.arrow {
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  max-height: 250px;
  overflow-y: auto;
  z-index: 100;
}

.dropdownOption {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.dropdownOption:hover {
  background-color: #007bff;
  color: white;
}

  @media (max-width: 768px) {
    .customInput,
    .contentTextarea {
      width: 100%;
      min-height: 200px;
      font-size: 1rem;
    }
  
    .resultContainer {
      width: 100%;
    }

    .resultContainer ul {
        padding-left: 15px; 
    }

    .ideasMaker {
        padding: 5px;
      }

      .selectDropdown {
        font-size: 1rem; 
    } 

    .customDropdown {
      width: 100%;
    }
  
    .dropdownMenu {
      max-height: 250px;
    }

  }
  