.container {
  padding: 10px;
  width: 80%;
}

.section {
  margin-top: 30px;
  border: 1px solid #ccc; 
  border-radius: 8px;
  padding: 10px;
}

.sectionContent {
  padding: 1px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 20px;
}

.filesList {
  margin-top: 10px;
}

.filesList h5 {
  font-size: 15px;
}

.deleteButton {
  margin-left: 5px;
  color: dark grey;
  cursor: pointer;
}

.uploadButtonContainer {
  margin-top: 10px;
  margin-bottom: 20px;
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  align-items: flex-start; 
}

.chatContainer {
  overflow-y: auto;
  max-height: 600px;
  flex-grow: 1;
  padding: 2px;
  background: #f5f5f5;
  border-radius: 8px;
  margin: 10px 0;
  width: 100%;
}

.questionInput {
  width: 100%;
  padding: 4px;
  font-size: 14px;
  height: 100px;
}

.backLink {
  margin-top: 100px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .actionsContainer {
    flex-direction: column;
  }

  .chatContainer {
    max-height: 1000px;
  }

 
  .questionInput {
    width: 100%;
    height: 150px;
  }

  .container {
    padding: 0px;
    width: 100%;
  }
}

/* Ensure the parent container of questionInput takes full width */
.questionInputContainer {
  width: 100%;
}