.topicGatherContainer {
    width: 60%;
    text-align: left;
    padding: 0px;

}

@media (max-width: 768px) {
    .topicGatherContainer {
        width: 100%;
    }
}

.keywordInput {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box; 
}

.searchButton, .summaryButton {
    cursor: pointer;
}

.searchButton:hover, .summaryButton:hover {
    background-color: #e0e0e0; /* Slightly darker shade on hover */
    border-color: #bbb; /* Slightly darker border on hover */
}